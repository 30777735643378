@import url(https://fonts.googleapis.com/css?family=Roboto);
.question-answer-block {
  background: #ffffff;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
  border-radius: 8px;
  padding: 20px 40px;
  width: 100%;
  flex-direction: column;
  display: flex;
  max-width: 80%;
  margin: 30px auto 20px auto;
  position: relative;
  z-index: 55;
}
.question-answer-block .details-header {
  text-transform: uppercase;
  font-weight: 800;
  margin-top: 24px;
}
@media (max-width: 992px) {
  .question-answer-block {
    align-self: center;
    word-wrap: break-word;
    min-width: 100%;
    padding: 20px;
  }
}
.question-answer-block.hide-arrow:before {
  display: none !important;
}
.question-answer-block:before {
  content: "";
  position: absolute;
  width: 0;
  height: 0;
  margin-left: -0.5em;
  top: 1px;
  left: 130px;
  box-sizing: border-box;
  border: 10px solid black;
  border-color: transparent transparent #fff #fff;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transform: rotate(135deg);
          transform: rotate(135deg);
  box-shadow: -2px 2px 1px rgba(0, 0, 0, 0.02);
}
.question-answer-block span:empty {
  padding-right: 0px;
}
.question-answer-block span {
  padding-right: 4px;
}
.question-answer-block .correct {
  color: #20a26b;
}
.question-answer-block .incorrect {
  color: #eb5757;
}

.question-answer-actions .action {
  font-size: 12px;
  line-height: 24px;
  font-weight: 500;
  color: rgba(29, 30, 29, 0.9);
}
.question-answer-actions .action.incorrect {
  padding-left: 20px;
  background: url("/static/img/assessment/icon-incorrect-simple.svg") no-repeat left center;
}
.question-answer-actions .action.correct {
  padding-left: 20px;
  background: url("/static/img/assessment/icon-correct-simple.svg") no-repeat left center;
}
.question-answer-actions .action span {
  font-size: 0.9em;
  color: rgba(29, 30, 29, 0.4);
  margin-left: 5px;
}
.question-answer-actions ol {
  max-width: 350px;
  counter-reset: my-awesome-counter;
  list-style: none;
  padding-left: 30px;
  margin-top: 10px;
}
.question-answer-actions ol li {
  margin: 0 0 0.5rem 0;
  counter-increment: my-awesome-counter;
  position: relative;
}
.question-answer-actions ol li::before {
  content: counter(my-awesome-counter);
  color: #20a26b;
  font-size: 11px;
  font-weight: bold;
  position: absolute;
  --size: 18px;
  left: calc(-1 * var(--size) - 10px);
  line-height: var(--size);
  width: var(--size);
  height: var(--size);
  top: 4px;
  background: #e4f1ec;
  border-radius: 50%;
  text-align: center;
}
.decision-player {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  flex-direction: column;
  transition: ease 0.2s all;
  width: 100%;
  background: rgba(0, 0, 0, 0.4);
}
.decision-player .question {
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.04), 0 4px 24px rgba(29, 30, 29, 0.04);
  border-radius: 3px;
  padding: 20px 40px 20px 40px;
  position: relative;
  white-space: nowrap;
  color: white;
  -webkit-animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
          animation: slide-top 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
.decision-player .question h1 {
  line-height: 1.5em;
  font-weight: 600;
  font-size: 1.8em;
}
@media (max-width: 992px) {
  .decision-player .question h1 {
    font-size: 1em;
  }
}
.decision-player .buttons {
  display: flex;
  flex-direction: row;
}
.decision-player .buttons button {
  margin: 10px 10px;
  -webkit-animation: scale-up-center 0.5s ease;
          animation: scale-up-center 0.5s ease;
  background: white;
  padding: 20px 40px;
  color: #000000;
  font-size: 16px;
  text-transform: unset;
  -webkit-transform: ease 0.2s all;
          transform: ease 0.2s all;
  opacity: 0.8;
}
@media (max-width: 992px) {
  .decision-player .buttons button {
    font-size: 12px;
    padding: 10px;
  }
}
.decision-player .buttons button:hover {
  opacity: 1;
  background-color: rgb(255, 255, 255) !important;
}
.decision-player .buttons button:focus, .decision-player .buttons button:active {
  box-shadow: 0 0 0 0.2rem #ffffff !important;
  background-color: rgb(255, 255, 255) !important;
}

.decision-video-item .jw-controlbar.jw-reset {
  display: none !important;
}
.decision-video-item .jw-nextup-container.jw-reset.jw-nextup-sticky.jw-nextup-container-visible {
  display: none !important;
}
.decision-video-item .jw-display-icon-container {
  display: none !important;
}
.decision-video-item .jw-title-primary.jw-reset-text {
  display: none !important;
}
.decision-video-item .jw-wrapper {
  background: #3c3c3c;
}
.decision-video-item .jw-wrapper.jw-reset {
  margin: 0 auto;
}
.decision-video-item .jw-aspect {
  padding-top: 53% !important;
}
.decision-video-item .jw-svg-icon-play path {
  display: none;
}

@-webkit-keyframes scale-up-center {
  0% {
    display: none;
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.8;
  }
}

@keyframes scale-up-center {
  0% {
    display: none;
    opacity: 0;
    -webkit-transform: scale(0.6);
            transform: scale(0.6);
  }
  1% {
    display: block;
    opacity: 0;
  }
  100% {
    -webkit-transform: scale(1);
            transform: scale(1);
    opacity: 0.8;
  }
}
@-webkit-keyframes slide-top {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
@keyframes slide-top {
  0% {
    -webkit-transform: translateY(-20px);
            transform: translateY(-20px);
  }
  100% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
  }
}
.numerical-scale {
  position: relative;
  overflow: visible;
  padding-bottom: 10px;
  max-width: 100%;
  display: flex;
  height: 50px;
  margin-bottom: 30px;
}
.numerical-scale:hover .MuiButtonBase-root label {
  opacity: 0 !important;
}
.numerical-scale .MuiButtonBase-root {
  height: 50px;
}
.numerical-scale .MuiButtonBase-root:last-child label {
  display: flex;
  width: 100%;
  justify-content: flex-end;
  padding-right: 10px;
}
.numerical-scale .MuiButtonBase-root:nth-child(1) label {
  justify-content: flex-start;
  padding-left: 10px;
}
.numerical-scale .MuiButtonBase-root label {
  display: flex;
  width: 100%;
  justify-content: center;
  padding-right: 10px;
}
.numerical-scale .MuiButtonBase-root:hover label {
  opacity: 1 !important;
}
.numerical-scale.showLabels .MuiButtonBase-root:nth-child(1) label {
  opacity: 1;
}
.numerical-scale.showLabels .MuiButtonBase-root:last-child label {
  opacity: 1;
}
.numerical-scale .Mui-selected label {
  opacity: 1 !important;
}
.numerical-scale label {
  opacity: 0;
  position: absolute;
  margin-top: 5px;
  font-size: 12px;
  bottom: -36px;
  left: 4px;
  text-transform: none;
  font-weight: 400;
  color: rgba(52, 53, 52, 0.6);
  white-space: nowrap;
  text-align: center;
  transition: ease 0.2s all;
}
.MuiListItem-container .MuiListItemIcon-root {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  min-width: 50px;
}
@media (max-width: 992px) {
  .MuiListItem-container .MuiListItemIcon-root {
    min-width: 40px;
  }
}
.dhl-email {
  display: flex;
  font-size: 12px;
}
.dhl-email .icon {
  height: 21px;
}
.dhl-email--left {
  background-color: #ffcc00;
  padding: 4px 16px;
}
.dhl-email--right {
  display: flex;
  flex-direction: column;
}
.dhl-email--right--header {
  background-color: #d2002e;
  color: white;
  text-align: right;
  padding-right: 8px;
}
.dhl-email--right--content {
  padding: 24px;
  background-color: white;
}
.dhl-email--right--content__red {
  color: #d2002e;
}
.dhl-email--right--content__lighter {
  opacity: 0.7;
}
/* Mdlz specific */
.mdlz-newsletter .mdlz-email-header {
  margin-top: 20px;
  margin-bottom: 10px;
}
.mdlz-newsletter .mdlz-email-header img {
  margin-left: -14px;
  max-width: 100%;
  width: 100%;
}
.mdlz-newsletter .blue {
  padding: 16px 20px 5px 20px;
  font-size: 12px;
  background: #afc5db;
}
.mdlz-newsletter .img {
  display: flex;
  justify-content: center;
}
.mdlz-newsletter .img img {
  padding: 10px 5px;
  width: 110px;
  padding-top: 26px;
}
.mdlz-newsletter .mdlz-email-footer {
  padding: 10px 0;
  font-size: 10px;
  line-height: 140%;
  color: #000000;
  opacity: 0.7;
}
.mdlz-newsletter ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
.assessment-presentation {
  display: flex;
  justify-content: center;
  padding-top: 60px;
  height: 100%;
}
@media (max-width: 992px) {
  .assessment-presentation {
    padding-top: 10px;
  }
}
.assessment-presentation .password {
  background: rgba(29, 30, 29, 0.03);
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-self: flex-start;
  margin: 3px 0 10px 0;
}
.assessment-presentation .image {
  text-align: center;
}
@media (max-width: 992px) {
  .assessment-presentation .image {
    align-self: center;
    padding-bottom: 20px;
  }
  .assessment-presentation .image img {
    width: 100%;
    align-self: center;
  }
}
.assessment-presentation .email-gmail {
  margin-top: 20px;
  width: 560px;
  box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.08), 0px 4px 24px rgba(29, 30, 29, 0.08);
  border-radius: 8px;
  padding: 20px 20px 40px 20px;
  margin-bottom: 40px;
  max-width: 100%;
  position: relative;
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail {
    width: 100%;
    padding: 16px;
  }
}
.assessment-presentation .email-gmail .email-header {
  display: flex;
}
.assessment-presentation .email-gmail .profile-img {
  border-radius: 50%;
  color: white;
  font-size: 17px;
  font-weight: 700;
  padding: 8px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  height: 40px;
  width: 40px;
  margin-top: -5px;
}
@media (max-width: 560px) {
  .assessment-presentation .email-gmail .profile-img {
    margin-right: 10px;
  }
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail .details-window.tooltip-from {
    margin-top: 20px;
    visibility: visible !important;
  }
}
.assessment-presentation .email-gmail .details-tooltip {
  position: relative;
  display: inline-block;
  padding-left: 4px;
}
.assessment-presentation .email-gmail .details-tooltiptext {
  visibility: hidden;
  width: 120px;
  background-color: black;
  color: #fff;
  text-align: center;
  border-radius: 6px;
  padding: 5px 0;
  /* Position the tooltip */
  position: absolute;
  z-index: 1;
  top: 100%;
  left: 50%;
  margin-left: -60px; /* Use half of the width (120/2 = 60), to center the tooltip */
}
.assessment-presentation .email-gmail .email-top-line {
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  align-items: flex-start;
  margin-top: -5px;
  margin-left: 7px;
  width: 100%;
  flex-direction: row;
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail .email-top-line {
    margin-left: 0;
    justify-content: space-between;
    align-items: flex-start;
    width: 100%;
  }
}
.assessment-presentation .email-gmail .email-top-line .details-header {
  justify-content: flex-start;
  align-items: center;
  display: flex;
  width: 100%;
  font-size: 14px;
  font-weight: 600;
  padding-left: 4px;
}
.assessment-presentation .email-gmail .email-top-line .details-header .email-time {
  color: rgba(52, 53, 52, 0.8);
  font-size: 12px;
  font-weight: 400;
  justify-self: flex-end;
  margin-left: 10px;
}
.assessment-presentation .email-gmail .email-top-line .details-header .mobile-email-time {
  margin-left: 5px;
  font-size: 12px;
  font-weight: 400;
  color: rgba(52, 53, 52, 0.6);
  margin-left: 10px;
}
.assessment-presentation .email-gmail .email-top-line .details-header .email-icons {
  margin-left: auto;
}
.assessment-presentation .email-gmail .email-top-line .right {
  font-weight: 300;
  font-size: 12px;
  line-height: 14px;
}
.assessment-presentation .email-gmail .email-top-line .right img {
  padding: 0;
}
.assessment-presentation .email-gmail .email-hover-text {
  font-size: 12px;
  padding: 0px 2px;
  cursor: pointer;
  transition: ease 0.2s all;
  font-weight: normal;
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail .email-hover-text .to-field {
    margin-left: 50px;
  }
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail .email-hover-text {
    position: static;
  }
}
.assessment-presentation .email-gmail .email-hover-text:hover .hover-details {
  opacity: 1;
}
.assessment-presentation .email-gmail .email-hover-text .hover-details {
  top: 57px;
  left: 70px;
  opacity: 0;
  padding: 4px 8px;
  border-radius: 4px;
  background: white;
  white-space: nowrap;
  position: absolute;
  box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
  pointer-events: none;
}
.assessment-presentation .email-gmail .email-text {
  font-family: "Roboto", sans-serif;
  position: relative;
}
.assessment-presentation .email-gmail .email-text .link {
  cursor: pointer;
}
.assessment-presentation .email-gmail .email-text .link.text {
  color: #2457c5;
  text-decoration: underline;
}
.assessment-presentation .email-gmail .email-text .icon {
  height: 21px;
}
.assessment-presentation .email-gmail .email-text .lighter {
  opacity: 0.7;
}
.assessment-presentation .email-gmail .email-text .body {
  max-width: 100%;
  /*font-family: arial;*/
  font-size: 12px !important;
  line-height: 16px;
  padding: 4px 4px 4px 0;
}
.assessment-presentation .email-gmail .email-footer {
  font-size: 11px;
}
.assessment-presentation .email-gmail .email-footer .attachment {
  width: 180px;
}
.assessment-presentation .email-gmail .email-footer img {
  cursor: pointer;
}
.assessment-presentation .email-gmail .details-tooltiptext {
  background: white;
  -webkit-filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
          filter: drop-shadow(0px 2px 6px rgba(0, 0, 0, 0.15));
  border: 1px solid #cccccc;
  border-radius: 2px;
  color: black;
  width: 360px;
  opacity: 0;
}
.assessment-presentation .email-gmail .tooltip-from {
  display: flex;
  width: 80%;
  border: 1px solid #cccccc;
  padding: 16px;
  background-color: white;
  box-shadow: 0px 0px 21px -4px rgba(0, 0, 0, 0.17);
  position: absolute;
  font-size: 12px;
  cursor: pointer;
  transition: ease 0.2s all;
  left: 70px;
  top: 36px;
  padding: 12px;
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail .tooltip-from {
    box-shadow: none;
    border-radius: 6px;
    width: 100%;
    display: block;
    margin-top: 20px;
    position: static;
    margin-left: -50px;
    padding-bottom: 17px;
    box-sizing: content-box;
    overflow-wrap: break-word;
  }
}
.assessment-presentation .email-gmail .tooltip-from .left {
  padding-left: 20px;
  color: #999999;
  text-align: right;
}
@media (max-width: 922px) {
  .assessment-presentation .email-gmail .tooltip-from .left {
    width: 20% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
}
.assessment-presentation .email-gmail .tooltip-from .right {
  padding-left: 20px;
  text-align: left;
}
@media (max-width: 922px) {
  .assessment-presentation .email-gmail .tooltip-from .right {
    width: 80% !important;
  }
}
.assessment-presentation .email-gmail .email-attachment-hover {
  position: absolute;
  left: 0;
  bottom: 0;
  font-size: 12px;
  background: #dfe1e6;
  padding: 2px 6px;
  z-index: 1000;
}
.assessment-presentation .email-gmail .email-attention {
  position: absolute;
  bottom: -60px;
  background: #e8eae9;
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 10px;
  transition: ease 0.2s all;
}
@media (max-width: 992px) {
  .assessment-presentation .email-gmail .email-attention {
    margin-bottom: 20px;
  }
}
.assessment-presentation .email-attention {
  opacity: 1;
  background: #e8eae9;
  padding: 10px 20px;
  font-size: 12px;
  border-radius: 10px;
  transition: ease 0.2s all;
  margin-left: 20%;
  margin-right: 20%;
  margin-top: 8px;
}

.image-zoomed {
  position: fixed;
  background: rgba(52, 53, 52, 0.9);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}
.image-zoomed img {
  max-width: 95%;
}
.image-zoomed:after {
  content: "";
  cursor: pointer;
  position: absolute;
  background: black url("/static/img/icon-close.svg") no-repeat center center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 10px;
  left: 10px;
}

.disclaimer {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 16px;
}
.disclaimer .disclaimer-icon {
  height: 22px;
  margin-right: 5px;
}
.assessment-presentation .question {
  max-width: 560px;
  width: 100%;
  flex-direction: column;
  display: flex;
  margin: 0 auto;
}
@media (max-width: 992px) {
  .assessment-presentation .question {
    max-width: initial;
    padding: 20px 10px !important;
  }
}
.assessment-presentation .question p {
  font-size: 16px;
  color: rgba(52, 53, 52, 0.8);
  white-space: pre-line;
}
.assessment-presentation .question normal {
  font-style: normal;
  font-weight: 400;
  color: rgba(52, 53, 52, 0.8);
}
.assessment-presentation .question .disclaimer {
  font-size: 12px;
  margin-bottom: 0;
  margin-top: 32px;
}
.assessment-presentation .question .disclaimer .disclaimer-icon {
  height: 22px;
  margin-right: 5px;
}
.assessment-presentation .question .image {
  margin-top: 30px;
}
.assessment-presentation .chat-message {
  display: flex;
}
@media (max-width: 992px) {
  .assessment-presentation .chat-message {
    align-self: center;
  }
}
.assessment-presentation .chat-message .img {
  display: flex;
  align-items: center;
  padding: 0 6px 0 0;
}
.assessment-presentation .chat-message .img img {
  width: 40px;
  height: 40px;
}
.assessment-presentation .chat-message .message {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;
  max-width: 380px;
}
.assessment-presentation .chat-message .message .top {
  display: flex;
  flex-direction: row;
}
.assessment-presentation .chat-message .message .top .name {
  font-weight: 700;
}
.assessment-presentation .chat-message .message .top .time {
  font-weight: 100;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
}
.assessment-presentation .chat-message .message .text {
  line-height: 1.3em;
  color: black;
  padding-top: 4px;
}
.assessment-presentation .password-tester {
  display: flex;
  flex-direction: revert;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.assessment-presentation .password-tester .MuiInputBase-root:after {
  content: "";
  background: url("/static/img/assessment/icon-eye.svg") no-repeat center center;
  padding: 12px 8px;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
}
.assessment-presentation .password-tester.visible .MuiInputBase-root:after {
  background: url("/static/img/assessment/icon-eye-closed.svg") no-repeat center center;
}
.assessment-presentation .password-tester .password-input {
  background: #FCFCFC;
  font-size: 14px;
}
@media (max-width: 992px) {
  .assessment-presentation .password-tester .password-input {
    margin-bottom: 20px;
  }
}
.assessment-presentation .password-tester .show-password {
  position: absolute;
  width: 54px;
  height: 55px;
  right: 123px;
}
@media (max-width: 992px) {
  .assessment-presentation .password-tester .show-password {
    right: 0px;
  }
}
.assessment-presentation .password-tester .MuiFormControl-root {
  display: flex;
  flex-direction: row;
  width: 100%;
}
@media (max-width: 992px) {
  .assessment-presentation .password-tester .MuiFormControl-root {
    flex-direction: column;
  }
}
.assessment-presentation .password-tester .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
}
.assessment-presentation .password-tester .MuiFormControl-root .MuiButtonBase-root {
  background: #20A26B;
  color: white;
  margin-left: 20px;
}
@media (max-width: 992px) {
  .assessment-presentation .password-tester .MuiFormControl-root .MuiButtonBase-root {
    width: 100%;
    margin: 0;
  }
}
.assessment-presentation .password-tester .MuiFormControl-root .MuiButtonBase-root.button-disabled {
  background: rgba(32, 162, 107, 0.9);
  text-indent: -9999px;
}
.assessment-presentation .password-tester .MuiButtonBase-root {
  width: 20%;
}
.assessment-presentation .password-tester-results {
  font-size: 12px;
  line-height: 20px;
  padding: 20px 0;
}
.assessment-presentation .password-tester-results .meter {
  height: 8px; /* Can be anything */
  position: relative;
  background: #F6F6F6;
  border-radius: 4px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
  display: relative;
  overflow: hidden;
  margin: 10px 0 20px 0;
}
.assessment-presentation .password-tester-results .meter span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}
.assessment-presentation .password-tester-results ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  display: flex;
  flex-wrap: wrap;
}
.assessment-presentation .password-tester-results ul li {
  padding-right: 30px;
  list-style: none;
  width: 33%;
}
.assessment-presentation .password-tester-results .action {
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
}
.assessment-presentation .password-tester-results .action.incorrect {
  color: #EB5757;
  padding-left: 20px;
  background: url("/static/img/assessment/icon-incorrect-simple.svg") no-repeat left center;
}
.assessment-presentation .password-tester-results .action.correct {
  color: #20A26B;
  padding-left: 20px;
  background: url("/static/img/assessment/icon-correct-simple.svg") no-repeat left center;
}

/* Material UI custom */
.assessment .MuiTypography-root {
  font-family: "Nunito Sans", sans-serif;
}

.MuiToggleButtonGroup-root .MuiButtonBase-root.numerical-scale-button.answer {
  flex-grow: 1;
  color: rgba(52, 53, 52, 0.9);
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.numerical-scale-button.answer .MuiToggleButton-label {
  font-weight: 700;
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.yes-no-question.answer {
  flex-grow: 1;
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.yes-no-question.answer .MuiToggleButton-label {
  font-weight: 700;
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.numerical-scale-button.question,
.MuiToggleButtonGroup-root .MuiButtonBase-root.yes-no-button {
  margin-right: 1px;
  background: #FFFFFF;
  color: #1D1E1D;
  transition: ease 0.2s all;
  flex-grow: 1;
  border: 1px solid #D2D2D2;
  border-radius: 4px;
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.numerical-scale-button.question:hover,
.MuiToggleButtonGroup-root .MuiButtonBase-root.yes-no-button:hover {
  background: #E8E8E8;
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.numerical-scale-button.question:focus,
.MuiToggleButtonGroup-root .MuiButtonBase-root.yes-no-button:focus {
  outline: none;
}
.MuiToggleButtonGroup-root .MuiButtonBase-root.numerical-scale-button.question .MuiToggleButton-label,
.MuiToggleButtonGroup-root .MuiButtonBase-root.yes-no-button .MuiToggleButton-label {
  font-weight: 700;
}
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-groupedHorizontal.numerical-scale-button:not(:last-child),
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-groupedHorizontal.yes-no-button:not(:last-child) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-groupedHorizontal.numerical-scale-button:not(:first-child),
.MuiToggleButtonGroup-root .MuiToggleButtonGroup-groupedHorizontal.yes-no-button:not(:first-child) {
  border-left: 1px solid transparent;
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.MuiToggleButtonGroup-root .MuiToggleButton-root.numerical-scale-button.question.Mui-selected,
.MuiToggleButtonGroup-root .MuiToggleButton-root.yes-no-button.Mui-selected {
  color: white;
  background-color: #343534;
}
.MuiToggleButtonGroup-root .MuiToggleButton-root.numerical-scale-button.question.Mui-selected:hover,
.MuiToggleButtonGroup-root .MuiToggleButton-root.yes-no-button.Mui-selected:hover {
  background-color: rgba(52, 53, 52, 0.9);
}
.MuiToggleButtonGroup-root .MuiToggleButton-root.numerical-scale-button.question.Mui-selected:active,
.MuiToggleButtonGroup-root .MuiToggleButton-root.yes-no-button.Mui-selected:active {
  border: 2px solid rgba(52, 53, 52, 0.9);
  background: #343534;
}

.yes-no-question {
  display: flex;
  background: white;
  box-shadow: 0px 4px 8px rgba(29, 30, 29, 0.06);
  border-radius: 4px;
  padding: 16px;
  justify-content: space-between;
  margin: 4px 0;
  align-items: center;
}
.yes-no-question .question {
  color: rgba(29, 30, 29, 0.8);
  font-weight: 600;
  margin: 0;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
}
@media (max-width: 992px) {
  .yes-no-question .question {
    font-size: 14px;
  }
}
.yes-no-question .MuiTypography-root {
  margin-right: auto;
}
.password {
  background: rgba(29, 30, 29, 0.03);
  padding: 8px 20px;
  border-radius: 4px;
  font-weight: bold;
  font-size: 18px;
  display: flex;
  align-self: flex-start;
  margin: 3px 0 10px 0;
}

.mobileOnly {
  display: none;
}
@media (max-width: 992px) {
  .mobileOnly {
    display: initial;
  }
}

.desktopOnly {
  display: initial;
}
@media (max-width: 992px) {
  .desktopOnly {
    display: none;
  }
}

.desktopOnly--carousel {
  display: flex;
}
@media (max-width: 992px) {
  .desktopOnly--carousel {
    display: none;
  }
}
/* Mdlz specific */
.mdlz-newsletter .mdlz-email-header {
  margin-top: 20px;
  margin-bottom: 10px;
}
.mdlz-newsletter .mdlz-email-header img {
  margin-left: -14px;
  max-width: 100%;
  width: 100%;
}
.mdlz-newsletter .blue {
  padding: 16px 20px 5px 20px;
  font-size: 12px;
  background: #afc5db;
}
.mdlz-newsletter .img {
  display: flex;
  justify-content: center;
}
.mdlz-newsletter .img img {
  padding: 10px 5px;
  width: 110px;
  padding-top: 26px;
}
.mdlz-newsletter .mdlz-email-footer {
  padding: 10px 0;
  font-size: 10px;
  line-height: 140%;
  color: #000000;
  opacity: 0.7;
}
.mdlz-newsletter ul {
  -webkit-padding-start: 20px;
          padding-inline-start: 20px;
}
.imageContainer {
  display: flex;
}
.imageContainer img {
  width: 100%;
  height: auto;
  object-fit: cover;
  padding: 3px;
}
.hoverable-image {
  width: 465px;
  height: 261px;
  position: relative;
  border-radius: 8px;
}
.chat-message {
  display: flex;
}
@media (max-width: 992px) {
  .chat-message {
    align-self: center;
  }
}
.chat-message .img {
  display: flex;
  align-items: center;
  padding: 0 6px 0 0;
}
.chat-message .img img {
  width: 40px;
  height: 40px;
}
.chat-message .message {
  border-radius: 4px;
  border: 1px solid #e0e0e0;
  padding: 12px;
  font-size: 14px;
  max-width: 380px;
}
.chat-message .message .top {
  display: flex;
  flex-direction: row;
}
.chat-message .message .top .name {
  font-weight: 700;
}
.chat-message .message .top .time {
  font-weight: 100;
  padding: 0 5px;
  font-size: 12px;
  line-height: 20px;
}
.chat-message .message .text {
  line-height: 1.3em;
  color: black;
  padding-top: 4px;
}
.password-tester {
  display: flex;
  flex-direction: revert;
  width: 100%;
  position: relative;
  margin-top: 20px;
}
.password-tester .MuiInputBase-root:after {
  content: "";
  background: url("/static/img/assessment/icon-eye.svg") no-repeat center center;
  padding: 12px 8px;
  position: absolute;
  right: 10px;
  top: 0;
  z-index: 1;
  text-align: center;
  width: 10%;
  height: 100%;
  pointer-events: none;
}
.password-tester.visible .MuiInputBase-root:after {
  background: url("/static/img/assessment/icon-eye-closed.svg") no-repeat center center;
}
.password-tester .password-input {
  background: #fcfcfc;
  font-size: 14px;
}
.password-tester .show-password {
  position: absolute;
  width: 54px;
  height: 55px;
  right: 123px;
}
.password-tester .MuiFormControl-root {
  display: flex;
  flex-direction: row;
  width: 100%;
}
.password-tester .MuiFormControl-root .MuiInputBase-root {
  width: 100%;
}
.password-tester .MuiFormControl-root .MuiButtonBase-root {
  background: #20a26b;
  color: white;
  margin-left: 20px;
}
.password-tester .MuiFormControl-root .MuiButtonBase-root.button-disabled {
  background: rgba(32, 162, 107, 0.9);
  text-indent: -9999px;
}
.password-tester .MuiButtonBase-root {
  width: 20%;
}

.password-tester-results {
  font-size: 12px;
  line-height: 20px;
  padding: 20px 0;
}
.password-tester-results .meter {
  height: 8px; /* Can be anything */
  position: relative;
  background: #f6f6f6;
  border-radius: 4px;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
  margin-bottom: 10px;
  display: relative;
  overflow: hidden;
  margin: 10px 0 20px 0;
}
.password-tester-results .meter span {
  display: block;
  height: 100%;
  border-top-right-radius: 8px;
  border-bottom-right-radius: 8px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  top: 0;
  left: 0;
  position: absolute;
  overflow: hidden;
}
.password-tester-results ul {
  -webkit-padding-start: 0;
          padding-inline-start: 0;
  display: flex;
  flex-wrap: wrap;
}
.password-tester-results ul li {
  padding-right: 30px;
  list-style: none;
  width: 33%;
}
.password-tester-results .action {
  font-size: 12px;
  line-height: 24px;
  font-weight: 700;
}
.password-tester-results .action.incorrect {
  color: #eb5757;
  padding-left: 20px;
  background: url("/static/img/assessment/icon-incorrect-simple.svg") no-repeat left center;
}
.password-tester-results .action.correct {
  color: #20a26b;
  padding-left: 20px;
  background: url("/static/img/assessment/icon-correct-simple.svg") no-repeat left center;
}
.post_linkedin,
.post_facebook {
  background: white;
  border: 1px solid #f3f4f4;
  max-width: 468px;
  width: 100%;
  margin: 0 auto;
  border-radius: 4px;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
}
.post_linkedin .post_header,
.post_facebook .post_header {
  display: flex;
  flex-direction: row;
  padding: 20px 20px 10px 20px;
}
.post_linkedin .post_header .right,
.post_facebook .post_header .right {
  padding-left: 10px;
}
.post_linkedin .post_header .right h3,
.post_facebook .post_header .right h3 {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  line-height: 20px;
  font-size: 14px;
  font-weight: bold;
}
.post_linkedin .post_header .right h3 span,
.post_facebook .post_header .right h3 span {
  font-weight: 400;
  margin-left: 5px;
  color: #999999;
}
.post_linkedin .post_header .right p,
.post_facebook .post_header .right p {
  margin: 0;
  padding: 0;
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 11.5px;
  color: #666666;
  letter-spacing: 0.03em;
}
.post_linkedin .post_text,
.post_facebook .post_text {
  font-family: Helvetica !important;
  line-height: 18px !important;
  /* or 129% */
  font-family: Helvetica;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 18px;
  /* or 129% */
  letter-spacing: 0.03em;
  padding-left: 24px !important;
  padding-right: 24px !important;
  margin: 0;
  padding-top: 10px !important;
  padding-bottom: 10px !important;
}
.post_linkedin .post_text p,
.post_facebook .post_text p {
  letter-spacing: 0.03em !important;
  font-size: 14px !important;
  margin-bottom: 0;
  color: #191919;
}
.post_linkedin .post_feedback,
.post_facebook .post_feedback {
  height: 44px;
  background: url("https://resources.awarego.com/img/assessment/linkedin-post-feedback.png") no-repeat left 20px center;
  font-family: Helvetica;
  padding-left: 80px;
  font-size: 12px;
  line-height: 44px;
  letter-spacing: 0.03em;
  color: #666666;
  opacity: 0.9;
  display: flex;
}
.post_linkedin .post_feedback.facebook,
.post_facebook .post_feedback.facebook {
  background: url("https://resources.awarego.com/img/assessment/post-feedback-like.png") no-repeat left 20px center;
  padding-left: 46px;
  justify-content: space-between;
  padding-right: 18px;
}
/*# sourceMappingURL=index.css.map */
